@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";
// Amplify Theme
@import "./@vex/styles/amplify-ui";


.calendar-input-box{
  height: 48px !important;
  .calendar-input{
    height: 100%;
    width: 100% !important;
    padding-right: 32px;
    background-color: transparent;
  }

  .calendar-input:focus-visible{
    outline: 0px;
  }
}


// .md-drppicker {
//   position: absolute;
//   font-family: Roboto,sans-serif;
//   color: inherit;
//   border-radius: 4px;
//   width: 400px;
//   padding: 4px;
//   margin-top: -10px;
//   overflow: hidden;
//   z-index: 1000;
//   font-size: 14px;
//   background-color: #fff;
//   box-shadow: 0 2px 4px #00000029, 0 2px 8px #0000001f;
//   top: auto !important;
//   right: 10px !important;
//   left: unset !important;
// }

.md-drppicker .clear svg{
  display: none;
}

.filter-dropdowns {
  .multiselect-dropdown {
    .dropdown-btn{
      padding: 12px 40px 12px 10px !important;
      border-radius: 0px !important;
      border-color: #ffffff !important;
      background-color: white !important;
      .dropdown-multiselect__caret{
        height: 48px !important;
      }
      .selected-item-container {
        .selected-item{
          background-color: #4f46e5 !important;
          border-color: #4f46e5 !important;
          max-width: unset !important;
        }
      }
    }
  }
}

.country-dropdown {
  .multiselect-dropdown {
    .dropdown-btn{
      padding: 12px 40px 12px 10px !important;
      border-radius: 0px !important;
      border-color: #e5e5e5 !important;
      .dropdown-multiselect__caret{
        height: 48px !important;
      }
      .selected-item-container {
        .selected-item{
          background-color: #4f46e5 !important;
          border-color: #4f46e5 !important;
        }
      }
    }
  }
}

.dropdown-list{
  width: unset !important;
}



// .width-correction{
//   input{
//     padding-right: 32px;
//   }
//   .mat-mdc-form-field-infix{
//     width: unset !important;
//   }
// }


/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --vex-sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
html {
  // --vex-color-primary: blue;
  // --vex-color-primary-contrast: white;

  // --vex-color-accent: yellow;
  // --vex-color-accent-contrast: black;

  // --vex-color-warn: yellow;
  // --vex-color-warn-contrast: black;
}
.custm_spinner_css{
  width: 60px!important;
  height: 60px!important;
  text-align: center;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 24px;

}
.tleft{
  text-align: left;
}

.width_350{
  width: 350px;
}

.changestable .mdc-data-table__header-row{
  height: 40px!important;
}

/* Nested table styling */
.nested-table {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 8px;
  background-color: #f4f4f4!important;
}
.nested-table th.mat-header-cell {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
  padding: 6px;
  border-bottom: 1px solid #ccc;
}
.nested-table td.mat-cell {
  padding: 6px;
  border-bottom: 1px solid #eee;
}

