// amplify customize UI 
.amplify-button {
    --amplify-components-button-border-radius: 0
}

.amplify-button[data-variation='primary'] {
    background: #8814b3;
    margin-top: 8px;
    padding: 15px;
    --amplify-components-button-font-weight: 300 !important;
}

.amplify-button[data-variation='primary']:hover {
    background: #8814b3;
}

.amplify-link {
    color: #8814b3 !important;
}

.amplify-button--link {
    --amplify-components-button-link-color: #000 !important;
    text-decoration: underline !important;
}

.amplify-button--link:hover,
.amplify-button--link:active,
.amplify-button--link:focus {
    background-color: transparent !important;
    color: #8814b3 !important;
    box-shadow: none !important;
}

.amplify-input {
    --amplify-components-fieldcontrol-border-radius: 0;
    --amplify-components-fieldcontrol-padding-block-start: 0.8rem;
    --amplify-components-fieldcontrol-padding-block-end: 0.8rem;
}

[data-amplify-authenticator][data-variation=modal] {
    --amplify-components-authenticator-modal-background-color: #350048;
    // background-image: url(/assets/images/hotel-login-bg.png);
}


@media (min-width: 30rem) {
    [data-amplify-authenticator] [data-amplify-container] {
        --amplify-components-authenticator-container-width-max: 550px;
        // width: auto !important;
    }
}